.table {
    width: 680px;
    margin-right: 20px;
    z-index: 1;
}

.panelTitle {
    font-size: 24px;
    text-align: left;
    color: #4E4E4E;
    font-family: Roboto, Medium;
    margin: 0;
    margin-left: 24px;
    margin-top: 24px;
    background: 'inherit';
}

.panel-desc {
    font-size: 16px;
    text-align: left;
    color: #9D9D9D;
    font-family: Roboto, Regular;
    margin: 0;
    margin-left: 24px;
}

.empty-page-title {
    text-align: 'center';
    font-size: '24px';
    font-family: Roboto Slab, Regular;
    color: #9D9D9D;
}

.empty-page-desc {
    text-align: center;
    font-size: 18px;
    font-family: Roboto, Regular;
    color: #9D9D9D;
}