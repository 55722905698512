.panels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.number-panel {
    display: flex;
    width: 250px;
    overflow: visible !important;
    position: relative;
    justify-content: flex-end;
}

.panel-text {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-right: 10px;
    margin-bottom: 20px;
    position: inherit;
    top: inherit;
    left: inherit;
    opacity: inherit;
    width: auto;
    height: auto;
}

.panel-text-title {
    color: #9D9D9D;
    font-family: Roboto, Regular;
    font-size: 16px;
    margin: '0';
    opacity: inherit;
}

.panel-text-number {
    font-family: Roboto, Medium;
    font-size: 36px;
    color: #4E4E4E;
    margin: 0;
    text-align: end;
}

.icon-background {
    display: flex;
    background-color: #9D9D9D;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 10px;
    top: -15px;
    border-radius: 4px;
}