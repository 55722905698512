.dashboard-page {
    display: flex;
    flex-direction: column;
}

.dashboard-title {
    min-height: auto;
}

.dashboard-title h1 {
    margin-bottom: 5px;
}

.headers {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: inherit;
    margin-bottom: 25px;
}

.dates-panel {
    display: flex;
    flex-direction: row;
}

.custom-dates {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-top: 16px;
}

.custom-dates > div {
    margin-right: 10px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
}

.show-more {
    width: 10em;
}

.custom-head-id {
    width: 5%;
}

.custom-head-value {
    width: 20%;
}

.custom-head-field {
    width: 20%;
}

.custom-head-datetime {
    width: 20%;
}