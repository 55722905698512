.panel {
    width: 440px;
    margin-right: 20px;
    margin-top: 0px;
}

.panel-title {
    font-size: 24px;
    text-align: left;
    color: #4E4E4E;
    font-family: Roboto, Medium;
    margin: 0;
    margin-left: 24px;
    margin-top: 24px;
}

.panel-desc {
    font-size: 16px;
    text-align: left;
    color: #9D9D9D;
    font-family: Roboto, Regular;
    margin: 0;
    margin-left: 24px
}

.empty-page-title {
    text-align: center;
    font-size: 24px;
    font-family: Roboto Slab, Regular;
    color: #9D9D9D;
}